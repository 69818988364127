type ContactsInterface = {
  id: string;
  email: string;
  phone: string;
  facebook: string;
  whatsapp: string;
  twitter: string;
  tekTok: string;
  instagram: string;
  created_at: Date;
  updated_at: Date;
};

export const useContacts = () => {
  const {$api} = useNuxtApp();
  const contacts = useState<ContactsInterface | null>("contacts", () => null);

  /**
   * Return contacts if exist => :::
   */
  if (contacts.value?.id) {
    return {
      contacts,
      loading: false,
    };
  }

  const {data, pending} = useAsyncData(
    "contacts",
    async () => {
      const {
        data: {
          data: {contact},
        },
      } = await $api.get("/contacts");

      return contact;
    },
    {server: false, lazy: false, default: () => ({})}
  );

  watch(
    () => data?.value,
    (value) => {
      contacts.value = value;
    }
  );

  return {
    contacts,
    loading: pending,
  };
};
